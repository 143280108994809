import { Subject, BehaviorSubject } from 'rxjs';

const userObject = new Subject();
const userLoginData = new BehaviorSubject(Object);

class commonService {
    setData = (d) => {
        userObject.next({ value: d })
    }

    //clearData: () => userObject.next(),
    getData = () => {
        return userObject.asObservable()
    }

    setObj = (value) => {
        userLoginData.next(value);
    }

    getUserlogin = () => {
        return userLoginData.getValue();
    }
}

const commondataservice = new commonService();
export default commondataservice;