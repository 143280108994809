import axios from 'axios';
import { authContext, adalConfig } from './adalConfig';
// Add a request interceptor
axios.interceptors.request.use(
    config => {
        authContext.acquireToken(adalConfig.endpoints.api, (message, token, msg) => {
            
            if (!!token) {
                config.headers['Authorization'] = 'Bearer ' + token;
                //resolve(message)
            } else {
                // Do something with error of acquiring the token
                // reject(message)
            }
        })
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });
export default axios;