import React from "react";
import * as pbi from 'powerbi-client';
import { models }  from "powerbi-client";
import userservice from '../../services/userservice.js';

export default class UsageReport extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      accessToken: '',
      reportId: '',
      height: ((window.innerHeight -110)+ 'px'),
      powerheight: ((window.innerHeight -10) + 'px'),
      url: ""
    };
    
    // get usage report data
    userservice.getUsageEmbedInfo()
      .then((response) => {

        this.setState({ url: response.data.embedUrl });
        this.setState({ reportId: response.data.reportId });
        this.setState({ accessToken: response.data.accessToken });
        this.showReport(response);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  // display report
  showReport(response) {
    let config = {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: response.data.accessToken,
      embedUrl: response.data.embedUrl,
      id: response.data.reportId,
      settings: {
        filterPaneEnabled: false,
        layoutType: 1
      }
    };
        
    let reportContainer = document.getElementById('dvreport');
    let powerbi1 = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    let report = powerbi1.embed(reportContainer, config);
    report.off("loaded");
    report.on("loaded", () => {
    });
    report.on("error", (ex) => {
    });
  }

  render() {
    
    return (
      <div style={{marginBottom: "50px"}}>
        <div id="dvreport" style={{ height: this.state.powerheight }} >

        </div>
      </div>
    );
  }
}
