import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Modal } from 'react-bootstrap';
import postingservice from '../../services/postingservice.js';
import moment from "moment";
import PowerBiReport from '../Report/powerbireport';
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authContext } from "../../adalConfig";

toast.configure();

export default class ViewPosting extends React.Component {
  constructor(props) {
    super();
    this.state = {

      showHide1: false,
      showHide2: false,
      showHide3: false,
      showHide4: false,
      imagePath: "",
      toemails: "",
      toCC: "",
      comments: "",
      commentsfordelete: "",
      data: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      allhouses: [],
      deletedata: [],
      birdsHeaders: [],
      imageslist: [],
      currenthouseimageslist: [],
      uniquelesioncode: [],
      birdslist: [],
      selectedimage: null,
      isLoading: true,
      status: false,
      selectedhouseirn: null,
      selectedformirn: null,
      selectedformName: null,
      selectedHouse: null,
      selectedBird: null,
      blobstorageURL: '',
      selectedFile: [],
      current: 0,
      nextIndex: 0,
      currentIndex: 0,
      row: "",
      selected: false,
      report: [],
      isradioDisable: false,
      dataflagupdatesummary: [],
      isActive: true,
      showPrevButton: true,
      showNextButton: true,
      sat: ''
    }

    let id = this.getQueryVariable('irn');
    const userEmail = authContext.getCachedUser() !== null ? authContext.getCachedUser().userName.toLocaleLowerCase() : '';
    // get posting session information
    postingservice.getPostingSessionDetailsById(id + '/' + userEmail).then((response) => {
      if (response.data.isAccessToPosting === false) {
        window.location.href = "/NotAuthorised";
        return;
      }
      if (response.data.postingSession === null) {
        this.setState({ data: null });
      }
      console.log(response.data)
      
      // handle success      
      this.setState({ data: response.data.postingSession })
      this.setState({ blobstorageURL: response.data.blobstorageURL });
      this.setState({ data2: response.data.formsList.sort(this.sortIt('proteinfarmname')) });
      let formid = response.data.formsList[0].irn;
      let houses = response.data.housesList.filter(x => x.proteinfarmsirn === formid).sort(this.sortIt('houseno'));
      this.setState({ selectedformirn: formid });
      this.setState({ data3: response.data.housesList.filter(x => x.proteinfarmsirn === formid).sort(this.sortIt('houseno')) });
      this.setState({ data4: response.data.proteinentities });
      this.setState({ data5: response.data.postingsessionentries });
      this.setState({ imageslist: response.data.postingsessionentriesimages });
      this.setState({sat: response.data.sat })
      this.setState({ selectedhouseirn: houses[0].irn });
      this.setState({ selectedHouse: houses[0].houseno });
      this.setState({ selectedformName: response.data.formsList[0].proteinfarmname });
      let protinelist = response.data.proteinentities;
      let houseirn = houses[0].irn;
      this.loadbirdsList(protinelist, response.data.postingsessionentries.sort(this.sortIt('lesioncode')), houseirn);

      this.setState({ allhouses: response.data.housesList });
      this.createStateObject(response.data);
      this.setState({ report: response.data.detailedreportReq });
      let isradioDisableVal = true;

      if (response.data.detailedreportReq !== null && response.data.detailedreportReq !== undefined && response.data.detailedreportReq.length > 0) {
        let summaryreport = response.data.detailedreportReq.filter(x => x.isactive === '1');
        this.setState({ reportyes: response.data.detailedreportReq[0].postsessionirn });
        if (summaryreport !== null && summaryreport !== undefined && summaryreport.length > 0) {
          isradioDisableVal = false;
        }
      }

      if (isradioDisableVal) document.getElementById("rdbyes").checked = true;
      this.setState({ isradioDisable: isradioDisableVal });
      this.setState({ isLoading: false });
    }).catch((error) => {
      // handle error
      this.setState({ isLoading: false });
      this.setState({ data: null });
    })
  }

  // load the birds details
  loadbirdsList(protinelist, postingsessionentries, houseirn) {

    let allprotinelist = protinelist.filter(x => x.proteinhousesirn === houseirn);
    let birdslist = [];
    allprotinelist.forEach(element => {
      let data = postingsessionentries.filter(x => x.proteinentitiesirn === element.irn).sort(this.sortItWithInt('birdno'));

      birdslist.push(...data);
    });

    let uniquebirs = [...new Set(birdslist.map(item => item.birdno))];
    this.setState({ birdslist: birdslist });
    birdslist = birdslist.sort(this.sortItWithInt('defaultorder'));
    let uniquelesioncode = [...new Set(birdslist.map(item => item.lesioncode))];
    
    this.setState({ birdsHeaders: uniquebirs });
    this.setState({ uniquelesioncode: uniquelesioncode });
  }

  // delete posting
  removeData() {
    document
      .querySelectorAll(".error-border")
      .forEach((obj) => obj.classList.remove("error-border"));
    if (this.state.commentsfordelete === undefined || this.state.commentsfordelete === null || this.state.commentsfordelete === "" || (this.state.commentsfordelete && this.state.commentsfordelete.trim()) === "") {
      if (!this.validatereason()) {
        toast.error("Please enter reason for delete");
        return false;
      }
    }
    const name = authContext.getCachedUser() !== null
      ? authContext.getCachedUser().profile.name : "";

    let id = this.getQueryVariable('irn');
    let paramObj = { postingirn: id, displayname: name, reasonfordelete: this.state.commentsfordelete.replace(/(\r\n|\n)/g, "<br/>") };
    this.setState({ isLoading: true });
    this.setState({ isActive: false });
    // delete posting based on posting session irn
    postingservice.deletepostingSession(paramObj).then((response) => {
      // handle success      
      this.setState({ deletedata: response.data })
      toast.success("Deleted Successfully");
      this.setState({ commentsfordelete: "" });
      this.setState({ showHide2: !this.state.showHide2 });
      
      setTimeout(
        () => {
          window.location.href = "/";
          this.setState({ isLoading: false })
        },
        1000
      );
    }).catch((error) => {
      //handle error
      this.setState({ isActive: true });
      toast.error("Not Deleted Successfully");
    })
    this.setState({ deletedata: this.state.deletedata })
  }

  // Send Feedback modal popup
  handleModal2ShowHide() {
    this.setState({ showHide1: !this.state.showHide1 })
  }

  // Confirm Deletion modal popup
  handleModal1ShowHide() {
    this.setState({ showHide2: !this.state.showHide2 })
  }

  // Summary Report modal popup
  handleModal4ShowHide() {
    this.setState({ showHide4: !this.state.showHide4 })
  }

  // image modal popup
  handleModal3ShowHide(bird, column, rowValue) {

    if (bird === undefined || (bird !== undefined && bird.imagecount !== '0')) {
      this.setState({ showHide3: !this.state.showHide3 })
    }

    if (bird !== undefined && bird.imagecount !== '0') {
      let currenthouseimages = this.state.imageslist.filter(x => x.proteinentitiesirn === bird.proteinentitiesirn);

      let image = currenthouseimages.filter(x => x.postingsessionentryirn === bird.pk_irn);

      let curIndex = currenthouseimages.findIndex(x => x.postingsessionentryirn === bird.pk_irn);

      this.setState({ showPrevButton: (curIndex !== 0) });
      this.setState({ showNextButton: (currenthouseimages.length !== (curIndex + 1)) });

      this.setState({ currenthouseimageslist: currenthouseimages });
      this.setState({ selectedimage: image[0] });
      this.setState({ selectedBird: bird });
      this.setState({ currentIndex: curIndex });
      this.setState({ row: rowValue });
      this.setState({ selected: false });
    }
  }

  // show next image in the image popup if any
  increaseIndex() {

    let curIndex = this.state.currentIndex + 1;
    let image = this.state.currenthouseimageslist[curIndex];
    let currenthouseimages = this.state.currenthouseimageslist;
    this.setState({ selectedimage: image });
    this.setState({ currentIndex: curIndex });
    this.setState({ showPrevButton: (curIndex !== 0) });
    this.setState({ showNextButton: (currenthouseimages.length !== (curIndex + 1)) });

    let sltdBird = this.state.birdslist.filter(x => x.pk_irn === image.postingsessionentryirn);
    this.setState({ selectedBird: sltdBird[0] });
  }

  // show previous image in the image popup if any
  decreaseIndex() {

    let curIndex = this.state.currentIndex - 1;
    let image = this.state.currenthouseimageslist[curIndex];
    let currenthouseimages = this.state.currenthouseimageslist;
    this.setState({ selectedimage: image });
    this.setState({ currentIndex: curIndex });
    this.setState({ showPrevButton: (curIndex !== 0) });
    this.setState({ showNextButton: (currenthouseimages.length !== (curIndex + 1)) });
    let sltdBird = this.state.birdslist.filter(x => x.pk_irn === image.postingsessionentryirn);
    this.setState({ selectedBird: sltdBird[0] });
  }

  // comments validation when delete the posting
  validatereason() {
    let isValidcomments = true;
    document.querySelectorAll(".error-border").forEach(obj => obj.classList.remove("error-border"));
    if (this.state.commentsfordelete === undefined || this.state.commentsfordelete === "" || (this.state.commentsfordelete && this.state.commentsfordelete.trim() === "")) {
      document.getElementById("commentsfordelete").classList.add("error-border");
      isValidcomments = false;
    }
    return isValidcomments;
  }

  // validation for send email
  validate() {

    let isValid = true;
    document.querySelectorAll(".error-border").forEach(obj => obj.classList.remove("error-border"));
    if (this.state.toemails === undefined || this.state.toemails === "") {
      document.getElementById("toemails").classList.add("error-border");
      isValid = false;
    }
    if (this.state.comments === undefined || this.state.comments === "" || (this.state.comments && this.state.comments.trim() === "")) {
      document.getElementById("comments").classList.add("error-border");
      isValid = false;
    }

    return isValid;
  }

  // convert  to base64 string
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  // attachment of file while sending the email
  onChangeHandler(e, el) {
    const selectedfiles = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = e.target.files[i];
      let fileName = file.name;
      let fileType = file.type;

      this.getBase64(file).then(
        data => {
          selectedfiles.push({
            attachmentid: 0,
            documentname: fileName,
            documenttype: '',

            documentformat: fileType,
            fileContent: data.split(';base64,')[1],
            isdeleted: false
          })
        }
      );
    }
    this.setState({ selectedFile: selectedfiles });
  }

   // file input change
  resetFiles = (e) => {
    e.target.value = null;
  };

  // send feedback email
  filterGo() {

    document
      .querySelectorAll(".error-border")
      .forEach((obj) => obj.classList.remove("error-border"));
    if (this.state.toemails === undefined || this.state.toemails === "") {
      if (!this.validate()) {
        toast.error("Please enter the To email address.");
        return false;
      }
    }

    var result = this.state.toemails.split(";");
    for (let i = 0; i < result.length; i++) {

      if (result[i] !== null && result[i] !== undefined && result[i] !== "") {
        if (!this.validateEmail(result[i])) {
          this.setState({ status: false });
          document.getElementById("toemails").classList.add("error-border");
          toast.error("Please enter valid  To email address");
          return;
        }
      }
    }

    var resultCC = this.state.toCC.split(";");
    for (let i = 0; i < resultCC.length; i++) {

      if (resultCC[i] !== null && resultCC[i] !== undefined && resultCC[i] !== "") {
        if (!this.validateEmail(resultCC[i])) {
          this.setState({ status: false });
          document.getElementById("toCC").classList.add("error-border");
          toast.error("Please enter valid CC email address");
          return;
        }
      }
    }

    if (this.state.comments === undefined || this.state.comments === null || this.state.comments === "" || (this.state.comments && this.state.comments.trim()) === "") {
      if (!this.validate()) {
        toast.error("Please enter email body");
        return false;
      }
    }

    const attachmentsInfo = [];
    if (this.state.selectedFile.length > 0) {
      for (var x = 0; x < this.state.selectedFile.length; x++) {
        attachmentsInfo.push(this.state.selectedFile[x]);
      }
    }
    let id = this.getQueryVariable('irn');
    const req = {};
    req.emailbody = this.state.comments.replace(/(\r\n|\n)/g, "<br/>");
    req.toemails = this.state.toemails;
    req.subject = "Feedback"
    req.toCC = this.state.toCC;
    req.document = attachmentsInfo;
    req.postingirn = id;

    this.setState({ status: false });
    // send Feedback email
    postingservice.sendFeedback(req)
      .then((response) => {
        // handle success
        this.setState({ status: true });
        toast.success("Mail sent Successfully");
        this.setState({ showHide1: !this.state.showHide1 })
        this.setState({ selectedFile: [] });
        let email = this.state.data.emailid;
        this.setState({ toemails: email });
        this.setState({ toCC: "" });
        this.setState({ comments: "" });
      })
      .catch((error) => {
        // handle error
        this.setState({ status: false });
        toast.error("email send  Failed");
      });
  }

  // get posting session irn
  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }

  // sort for integer's data
  sortItWithInt = sortBy => (a, b) => {

    if (parseInt(a[sortBy]) > parseInt(b[sortBy])) {
      return 1;
    } else if (parseInt(a[sortBy]) < parseInt(b[sortBy])) {
      return -1;
    }
    return 0;
  }

  // sort data
  sortIt = sortBy => (a, b) => {

    if ((a[sortBy] + "").toLowerCase() > (b[sortBy] + "").toLowerCase()) {
      return 1;
    } else if ((a[sortBy] + "").toLowerCase() < (b[sortBy] + "").toLowerCase()) {
      return -1;
    }
    return 0;
  }

  // validation for email
  validateEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  
  // used to set a new state for the input
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  // create state object
  createStateObject(data) {
    this.setState({ toemails: data.postingSession.emailid });
  }

  // display protein farm name based on posting session irn
  selectedhouse(item) {

    let houselist = this.state.allhouses.filter(x => x.proteinfarmsirn === item.irn);
    this.setState({ data3: houselist.sort(this.sortIt('houseno')) });
    this.setState({ selectedformirn: item.irn });
    this.setState({ selectedhouseirn: houselist[0].irn });
    this.setState({ selectedHouse: houselist[0].houseno });
    this.setState({ selectedformName: item.proteinfarmname });
    this.loadbirdsList(this.state.data4, this.state.data5, houselist[0].irn);
  }

  // display house/house's based on protein farm selected
  selecteddata(item) {
    this.setState({ selectedhouseirn: item.irn });
    this.setState({ selectedHouse: item.houseno });

    this.loadbirdsList(this.state.data4, this.state.data5, item.irn);
  }

  // update summary report 
  updateSummary = () => {
    let id = this.getQueryVariable('irn');

    let obj = { irn: id }
    postingservice.updateReportRequest(obj).then((response) => {
      // handle success
      document.getElementById("rdbyes").checked = true;
      this.setState({ isradioDisable: true });
      this.setState({ dataflagupdatesummary: response.data });
      toast.success('Updated successfully')
      this.setState({ reportno: this.state.reportno });
    }).catch((error) => {
      // handle error
      this.setState({ isLoading: false });
      toast.error('error')
    })
    this.setState({ dataflagupdatesummary: this.state.dataflagupdatesummary })
  }

  // on change
  changed() {
    console.log("changed");
  }

  render() {
    return (
      <LoadingOverlay active={this.state.isLoading} spinner>
        <div style={{ marginTop: "70px" }} id='data'>
          {this.state.data === null ? <div className="datanotfound">No Records Found
          <div><a href='/'>Click here to go to the homepage</a></div>
          </div> :
            <div className="mt-4">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {this.state.data.customername}
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="dashboard mr-4 ml-4">
                <div className="row pr-4 background-white mt-1">
                  <div className="col-sm-2 pt-3 border-right">
                    <p className="dashboardlabels">Affiliate</p>
                    <p className="dashboardlabeldynamicdata">{this.state.data.affiliatename}</p>
                  </div>
                  <div className="col-sm-2 pt-3 border-right">
                    <p className="dashboardlabels">Customer Name</p>
                    <p className="dashboardlabeldynamicdata">{this.state.data.customername}</p>
                  </div>
                  <div className="col-sm-3 pt-3 border-right">
                    <p className="dashboardlabels">Complex</p>
                    <p className="dashboardlabeldynamicdata">
                      {this.state.data.customerlocationname}
                    </p>
                  </div>
                  <div className="col-sm-2 pt-3 border-right">
                    <p className="dashboardlabels">Submitted By</p>
                    <p className="dashboardlabeldynamicdata">{this.state.data.displayname}</p>
                  </div>
                  <div className="col-sm-2 pt-3 border-right">
                    <p className="dashboardlabels">Submitted Date</p>

                    <p className="dashboardlabeldynamicdata">{moment(this.state.data.creationdate).format("DD/MM/YYYY")}</p>
                  </div>
                  <div className="col-sm-1 pt-3 ">
                    <p className="dashboardlabels">Status</p>
                    <p className="dashboardlabeldynamicdata">{this.state.data.status}</p>
                  </div>
                </div>
              </div>
              <div className="row mt-3">


                <div className="col-sm-6 mt-3" >
                  <div style={{ display: this.state.report !== null ? '' : 'none' }} >
                    <div className="form-check-inline ml-4" >
                      <label className="form-check-label">
                        <b>Is Report Generated ?</b>
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="optradio" id="rdbyes"
                          onClick={e => { this.updateSummary() }} onChange={this.changed} disabled={this.state.isradioDisable} />
                         Yes
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="optradio" disabled={this.state.isradioDisable} />
                        No
                     </label>
                    </div>
                  </div>
                </div>


                <div className="col-sm-6">
                  <div className="btn float-right">
                    <button type="button" id='SummaryReport' className="btn btn-outline-primary" onClick={() => this.handleModal4ShowHide()}>
                      Summary Report
              </button>
                    <Modal


                      dialogClassName="modal-90w"
                      show={this.state.showHide4}
                      centered
                      onHide={() => this.handleModal4ShowHide()}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header closeButton centered="true">
                        <Modal.Title>Summary Report</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <PowerBiReport></PowerBiReport>

                      </Modal.Body>

                    </Modal>
              &nbsp;&nbsp;&nbsp;
              <Modal
                      show={this.state.showHide2}
                      centered
                      onHide={() => this.handleModal1ShowHide()}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header closeButton centered="true">
                        <Modal.Title>Confirm Deletion</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="col-md-12 d-flex">
                          <label htmlFor="" className="col-form-label mr-2 pt-1">
                            Reason for Delete&nbsp;:{" "}<span className="req mr-2 pt-1">*</span>
                          </label>
                        </div>
                        <div className="form-group col-md-12 d-flex p_wrap">
                          <textarea
                            className="form-control mt-2"
                            onChange={this.handleChange}
                            name="commentsfordelete"
                            id="commentsfordelete"
                            rows="3"
                            value={this.state.commentsfordelete}
                            placeholder="Type your message here"
                          ></textarea>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        {this.state.isActive &&
                          (
                            <button
                            id='deletebutton'
                            className="btn btn-primary"
                              onClick={() => this.removeData()}
                            >
                              &nbsp; Yes &nbsp;
                            </button>
                          )}


                        <button
                         id='cancelbutton'
                          className="btn btn-link"
                          onClick={() => this.handleModal1ShowHide()}
                        >
                          Cancel
                  </button>
                      </Modal.Footer>
                    </Modal>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#sharefeedback"
                      id='Feedback'
                      onClick={() => this.handleModal2ShowHide()}
                    >
                      Send Feedback
              </button>
              &nbsp;&nbsp;&nbsp;
              <Modal
                      dialogClassName="my-modal"
                      size="lg"
                      centered
                      show={this.state.showHide1}
                      onHide={() => this.handleModal2ShowHide()}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header closeButton centered="true">
                        <Modal.Title
                          className="mt-2 modal-title  font15 font-bold"
                          id="contained-modal-title-vcenter"
                        >
                          Feedback
                  </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <div className="col-md-12 d-flex mt-2">
                            <label
                              htmlFor="toemails"
                              className="col-form-label mr-2 pt-1 mb-4"
                            >
                              To&nbsp;:&nbsp;<span className="req">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="toemails"
                              id="toemails"

                              value={this.state.toemails}
                              onChange={this.handleChange}
                              placeholder="Email"
                              className="form-control"
                            />
                          </div>
                         
                        </div>
                        <div className="col-md-12 d-flex">
                          <label htmlFor="toCC" className="col-form-label mr-2 pt-1 mb-3">
                            CC&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                          </label>
                          <input
                            type="text"
                            name="toCC"
                            id="toCC"
                            value={this.state.toCC}
                            onChange={this.handleChange}
                            placeholder="Email"
                            className="form-control"
                          />
                        </div>
                     
                        <div className="col-md-12 d-flex">
                          <label htmlFor="" className="col-form-label mr-2 pt-1">
                            Comments&nbsp;:{" "}<span className="req mr-2 pt-1">*</span>
                          </label>
                        </div>
                        <div className="form-group col-md-12 d-flex p_wrap">
                          <textarea
                            className="form-control mt-2"
                            onChange={this.handleChange}
                            name="comments"
                            id="comments"
                            rows="3"
                            value={this.state.comments}
                            placeholder="Type your message here"
                          ></textarea>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <input
                          type="file"
                          className="form-control-file "
                          name="selectedFiles"
                          id="exampleFormControlFile1"
                          multiple="multiple"
                          onChange={e => this.onChangeHandler(e, this)}

                          onClick={this.resetFiles}
                        />
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                          id='mailSendbutton'
                          onClick={() => this.filterGo()}
                        >
                          &nbsp; Send &nbsp;
                  </button>
                        <button
                          type="button"
                          className="btn btn-link"
                          data-dismiss="modal"
                          id='mailCancelButton'
                          onClick={() => this.handleModal2ShowHide()}
                        >
                          Cancel
                  </button>
                      </Modal.Footer>
                    </Modal>
                    <Modal

                      show={this.state.showHide3}
                      centered
                      onHide={() => this.handleModal3ShowHide()}
                      backdrop="static"
                      keyboard={false}>
                      <Modal.Header closeButton centered="true" className="modal-header noborderbottom">
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <img className="center"
                          src={this.state.selectedimage !== null ? this.state.blobstorageURL + this.state.selectedimage.protiensessionentryimagepath + this.state.sat : ''}
                          alt="Bird"
                        />

                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-center mb-0">
                            <li className="page-item" id='dec'>

                              <a className="page-link1" href="#!" tabindex="-1"
                                onClick={(e, index) => {
                                  this.decreaseIndex();
                                }}
                                style={{ display: this.state.showPrevButton ? '' : 'none' }}
                              ><i className="arrow left"></i> Previous</a>

                            </li>
                            <li className="page-item" id='inc'>

                              <a className="page-link1" href="#!" id="link1" onClick={(e) => {
                                this.increaseIndex()
                              }} style={{ display: this.state.showNextButton ? '' : 'none' }}>Next <i className="arrow right"></i></a>


                            </li>
                          </ul>
                        </nav>
                        <div className="table p-3 mt-0 mb-0">
                          <table className="table table-bordered rounded">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Farm Name</th>
                                <th scope="col">Flock Number</th>
                                <th scope="col">Bird</th>
                                <th scope="col">Lesion</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{this.state.selectedformName} </td>
                                <td>{this.state.selectedHouse}</td>
                                <td>{this.state.selectedBird !== null ? this.state.selectedBird.birdno : ''}</td>
                                <td>{this.state.selectedBird !== null ? this.state.selectedBird.lesioncode : ''}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Modal.Body>

                    </Modal>


                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#myModal"
                      id='DeletePostingButton'
                      onClick={() => this.handleModal1ShowHide()}
                    >
                      Delete Necropsy
              </button>

                  </div>
                </div>
              </div>

              <div className="row">
                <div className="container-fluid"></div>
                <div className="w-100" style={{ margintop: "30px" }}>
                  <div className="ml-4 pl-2 pr-2 mr-4 mb">
                    <div className="panel-bg mt-3">

                      <ul className="nav nav nav-pills pl-0">
                        {this.state.data2.map((item, i) => {
                          return (
                            <li className="nav-item" key={i}>
                              <a href="#!" onClick={(e) => this.selectedhouse(item)}
                                className={(item.irn === this.state.selectedformirn) ? 'nav-link active' : "nav-link"}
                                data-toggle="tab" id={"form" + i}>{item.proteinfarmname}</a>
                            </li>
                          )
                        })}
                      </ul>

                      <div className="tab-content">
                        <div
                          role="tabpanel"
                          className="tab-pane show active"
                          id="farm_01">
                          <ul className="nav pl-0">
                            {this.state.data3.map((item, i) => {
                              return (
                                <li className="nav-item" key={i}>
                                  <a
                                    className={(item.irn === this.state.selectedhouseirn) ? 'nav-link housename active' : "nav-link"}
                                    href='#!'
                                    onClick={(e) => this.selecteddata(item)}
                                    data-toggle="tab"
                                    id={"house" + i}>House No. {this.state.data3[i].houseno}</a>
                                </li>
                              )
                            })}
                          </ul>

                          <div className="tab-content">
                            <div
                              role="tabpanel"
                              className="tab-pane show active"
                              id="f1houseno_01">
                              <div className="p-3">
                                <table
                                  id="manage_sub_table"
                                  className="table dataTable table-bordered"
                                  style={{ width: "100%" }}>
                                  <thead>
                                    <tr>
                                      <th style={{ width: "20%" }}>Lesion</th>
                                      {this.state.birdsHeaders.map((v) => (
                                        <th>Bird {v}</th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.uniquelesioncode.map((v) => (
                                      <tr>
                                        <td>{v}</td>
                                        {this.state.birdsHeaders.map((b) => (
                                          <td data-column={JSON.stringify(b)} data-bird={JSON.stringify(v)}>
                                            <a className={(this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0] !== undefined && this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0].imagecount) === '0' ? "disablebirdlink" : "activebirdlink"} href="#!" id={"bird" + v + b}
                                              onClick={() => this.handleModal3ShowHide(this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0], b, v)}>


                                              {v === 'Sex' ? (isNaN(parseInt((this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0] !== undefined && this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0].lesionscorevalue))) ? '' :
                                                parseInt(this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0] !== undefined && this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0].lesionscorevalue) === 1 ? 'Female' : 'Male') :
                                                isNaN(parseInt((this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0] !== undefined && this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0].lesionscorevalue))) ? '' :
                                                  parseInt((this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0] !== undefined && this.state.birdslist.filter(x => x.birdno === b && x.lesioncode === v)[0].lesionscorevalue))}

                                            </a>
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </LoadingOverlay>
    );
  }
}
