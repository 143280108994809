import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
import * as config from './config';
export const adalConfig = {
    tenant: config.tenant,
    clientId: config.clientId,
    redirectUri: window.location.origin,
    endpoints: {
        api: config.clientId,
    },
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'localStorage',
    extraQueryParameter: 'scope=openid+profile'
};

export const authContext = new AuthenticationContext(adalConfig);
export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
//console.log(withAdalLoginApi);

