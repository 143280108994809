import React from "react";
function Footer() {
  // get present year 
  const getYear = () => {
  var d = new Date();
    var n = d.getFullYear();
    return n;
  };
  return (
    
    <footer className="footer mt-4 d-flex">
      
      <div className="cert-pad"> &copy;</div>
      <div className="foot-text">{getYear()} Elanco</div>
    </footer>
  );
}
export default Footer;
