import React, { Component } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import 'react-tabs/style/react-tabs.css';
import Posting from "./posting";
import UsageReport from "./usageReport";
import DetailedReportRequests from "./detailedReportRequests";
import ManageUsers from "./manageUsers";
import commondataservice from '../../services/commondataservice.js';
import Language from "./languagenew";

export class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdminUser: false
    };
    // get data
    commondataservice.getData().subscribe(message => {
      this.setState({ isAdminUser: message.value.isAdminUser });
    });
  }

  // open corresponding page on tab click
  handleTabClick = (key) => {
    if (key === "Posting") {

      return (<Posting></Posting>)
    }
    else if (key === "UsageReport") {
      return (<UsageReport></UsageReport>)
    }
    else if (key === "DetailedReportRequests") {
      return (<DetailedReportRequests></DetailedReportRequests>)
    }
    else if (key === "ManageUsers") {
      return (<ManageUsers></ManageUsers>)
    }
   else if (key === "language") {
      return (<Language></Language>)
    }

  }


  render() {

    return (

      <Tabs defaultActiveKey="Posting" id="uncontrolled-tab-example" className="mt-4 ml-4 mr-4"
        onSelect={(e) => this.handleTabClick(e)} mountOnEnter={true} style={{ paddingTop: '40px' }}>
        <Tab eventKey="Posting" title="Necropsies">
          <Posting></Posting>
        </Tab>
        <Tab eventKey="DetailedReportRequests" title="Detailed Report Requests">
          <DetailedReportRequests></DetailedReportRequests>
        </Tab>
        {
          this.state.isAdminUser === true ?
            <Tab eventKey="ManageUsers" title="Manage Users">
              <ManageUsers></ManageUsers>
            </Tab>
            : ''}
        {
          this.state.isAdminUser === true ?
            <Tab eventKey="UsageReport" title="Usage Report" >
              <UsageReport></UsageReport>
            </Tab>
            : ''}
            {
            this.state.isAdminUser === true ?
          <Tab eventKey="language" title="Language" >
            <Language></Language>
          </Tab>
          : ''}
      </Tabs>
    );
  }
}
export default Main;