import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
import './index.css';
import "./assests/css/styles.css"
import 'font-awesome/css/font-awesome.min.css';
import './assests/css/bootstrap.min.css';
import './assests/css/dataTables.bootstrap4.min.css';
// Custom styles for this template
import "./assests/css/main_styles.css";
import * as config from "./config"

const AUTHENTICATION_ENABLED = (config.tenant && config.clientId && config.tenant.length > 34
   && config.clientId.length > 34) && (window.location.pathname !== '/report');
runWithAdal(authContext, () => {

  // eslint-disable-next-line
  require('./auth.js');

},!AUTHENTICATION_ENABLED);

