//import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Header from './components/HeaderComponent/header';
import PowerBiReport from './components/Report/powerbireport';
import Footer from './components/FooterComponent/footer';
import 'bootstrap/dist/css/bootstrap.css';
import "jquery/dist/jquery.min.js"; // Have to install and import jQuery because of bootstrap modal's dependency
import NotAuthorized from './components/NotAuthorizedComponent/notauthorized';
import Main from './components/LandingComponent/main';
//import Posting from './components/LandingComponent/posting';
//import UsageReport from './components/LandingComponent/usageReport';
import ViewPosting from './components/LandingComponent/viewposting';
//import DetailedReportRequests from './components/LandingComponent/detailedReportRequests';
function App() {
  return (

    <div >
      {
        window.location.pathname !== '/report' ? <Header /> : null
      }

      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              <Main></Main>
            </Route>
            {/* <Route exact path="/Posting" >
                <Posting></Posting>
              </Route>
              <Route exact path="/UsageReport" >
                <UsageReport></UsageReport>
              </Route>
              <Route exact path="/DetailedReportRequests" >
                <DetailedReportRequests></DetailedReportRequests>
              </Route> */}
            <Route exact path="/ViewPosting" >
              <ViewPosting></ViewPosting>
            </Route>
            <Route exact path="/report">
              <PowerBiReport></PowerBiReport>
            </Route>
            <Route path="*" >
              <NotAuthorized />
            </Route>
          </Switch>
        </div>
      </Router>
      {
        window.location.pathname !== '/report' ? <Footer /> : null
      }

      <div className="overlay" style={{ display: 'none' }} id='div-overlay'></div>
    </div>

  );
}

export default App;
