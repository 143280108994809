import React from "react";
import { Nav, NavDropdown, Button } from "react-bootstrap";
import { authContext } from "../../adalConfig";
import logo from "../../logo_blue.svg";
import "../HeaderComponent/header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import userservice from '../../services/userservice.js';
import commondataservice from '../../services/commondataservice.js';

function Header() {
  
  const userEmail = authContext.getCachedUser() !== null ? authContext.getCachedUser().userName.toLocaleLowerCase() : '';
  const location = window.location;
  if (location.pathname.toLowerCase() !== '/notauthorised') {
    userservice.validateUser(userEmail).then((response) => {
       commondataservice.setData(response.data);
       commondataservice.setObj(response.data);
      // handle success 
      if (response.data !== null && response.data.isUserExistsinWeb === false && location.pathname.toLowerCase() !== '/notauthorised') {
       
        window.location.href = "/NotAuthorised"
        return;
      } else {
      
      }
    }).catch((error) => {
    });
  }

  // get name of the loggedin user
  const getName = () => {

    const name = authContext.getCachedUser() !== null
      ? authContext.getCachedUser().profile.name.split(" ") : "";

    let displayName = "";
    if (name !== "") {
      displayName = name.length > 1
        ? name[0].substring(0, 1) + name[1].substring(0, 1)
        : name[0].substring(0, 1);
    }
    return displayName;
  };

  return (
    <div>
      <Nav className="navbar fixed-top bg-white" activeKey="/">
        <div>
          <Nav.Item className="color-black">
            <Nav.Link
              className="navbar-brand nav-link-black pt-0 pb-0"
              href="/"
            >
              <img className="logo" alt="Elanco" src={logo} /> &nbsp;  <span className="logo_text">| &nbsp;HTSi Global Application </span>
            </Nav.Link>
          </Nav.Item>
        </div>
        <div>
          <Nav.Item className="ml-auto" style={{ display: "flex" }}>

            <NavDropdown
              title={
                <div className="" style={{ color: "#fff" }}>
                  {getName()}
                </div>
              }
              id="basic-nav-dropdown"
              className="justify-content-end profile-icon"
            >
              <NavDropdown.Item href="javascsript:void(0)">
                {authContext.getCachedUser() !== null
                  ? authContext.getCachedUser().profile.name
                  : ""}
              </NavDropdown.Item>
              <NavDropdown.Item href="javascsript:void(0)">
                {authContext.getCachedUser() !== null
                  ? authContext.getCachedUser().userName
                  : ""}
              </NavDropdown.Item>
              <NavDropdown.Item divider />
              <NavDropdown.Item>
                {authContext.getCachedUser() !== null ? (
                  <Button
                    className="btn btn-primary btn-inner w-100"
                    onClick={() => {
                      authContext.logOut();
                      localStorage.clear();
                    }}
                  >
                    Log out
                  </Button>
                ) : (
                    <Button className="btn btn-primary btn-inner w-100">
                      Log out
                    </Button>
                  )}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
        </div>
      </Nav>
    </div>
  );
}
export default Header;
