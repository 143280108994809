import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal } from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import postingservice from '../../services/postingservice.js';
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';
import commondataservice from '../../services/commondataservice.js';

const NoDataIndication = () => (
    <div className="spinner">
        <div className="text-center">No Records Found</div>
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
    </div>
);

let options = {
    showTotal: true,
    sizePerPageList: [{
        text: '25', value: 25
    }, {
        text: '50', value: 50
    }, {
        text: '75', value: 75
    }]
};

export default class ManagaUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateResultCompleted: null,
            dateResultCompleted1: null,
            isLoading: true,
            affiliatesList: [],
            customerList: [],
            customerListAll: [],
            gridData: [],
            isAdminUSer: false,
            userAffiliates: [],
            userirn: '',
            data: [],
            emaildata: [],
            firstname: '',
            emailiddata: '',
            usersirndata: '',
            customernamedata: '',
            setSelectedCountry: "",
            setCities: "",
            setSelectedCity: "",
            selectedCounty: "",
            showHide4: false,
            showHide2: false,
            showHide: false,
            email: "",
            isHidden: true,
            deletedata: [],
            deleteirn: '',
            activeflag: '',
            selectedcustomerirn: null,
            selectedcustomeraffiliateirn: null,
            value: '',
            userid: '',
            useriddata: '',
            selectedcustomerOption: [],
            customerirndata: [],
            affiliateirndata: []
        };
        let datauser = commondataservice.getUserlogin();
        this.setState({ userid: datauser.userid });

        //    // get Customers list 
        //     postingservice.getAllCustomers()
        //         .then((response) => {
        //             //handle success
        //             this.setState({ customerList: response.data });
        //             this.setState({ isLoading: false });
        //         })
        //         .catch((error) => {
        //             //handle error
        //             this.setState({ isLoading: false });
        //         });

        // get Customers list New
        postingservice.getAllCustomersNew()
            .then((response) => {
                //handle success
                this.setState({ customerList: response.data });
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                //handle error
                this.setState({ isLoading: false });
            });

        // get SAP CDC users information
        postingservice.getUsersInformation()
            .then((response) => {
                //handle success
                this.setState({ data: response.data });
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                //handle error
                this.setState({ isLoading: false });
            });
    }

    // define table columns
    columns = [
        {
            dataField: 'emailid', text: 'Email', sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            }
        },
        {
            dataField: 'customername', text: 'Customer', sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "18%" };
            }
        },
        {
            dataField: "",
            text: 'Actions',
            align: 'center',
            headerAlign: (column, colIndex) => 'center',
            formatter: (cellContent, row) => {
                if (row.isactive === '1') {
                    return (
                        <button type="button" className="btn btn-outline-danger" onClick={() => this.handleModal1ShowHide(row.usersirn, row.isactive)}>Deactivate</button>
                    )
                }
                else {
                    return (
                        <button type="button" className="btn btn-outline-success" onClick={() => this.handleModalShowHide(row.usersirn, row.isactive)}>&nbsp;&nbsp;Activate&nbsp;&nbsp;</button>
                    )
                }
            },
            headerStyle: (colum, colIndex) => {
                return { width: "7%" };
            }
        }
    ];

    // sort data
    sortIt = sortBy => (a, b) => {
        if (a[sortBy] > b[sortBy]) {
            return -1;
        } else if (a[sortBy] < b[sortBy]) {
            return 1;
        }
        return 0;
    }

    // Map User model popup
    handleModal4ShowHide() {
        this.setState({ showHide4: !this.state.showHide4 });
        this.setState({ email: '' });
        this.state.isHidden = true;
    }

    // Deactivate user model popup
    handleModal1ShowHide(irn, isactive) {
        this.setState({ showHide2: !this.state.showHide2 });
        this.setState({ deleteirn: irn });
        this.setState({ activeflag: isactive });
    }

    // Activate user model popup
    handleModalShowHide(irn, isactive) {
        this.setState({ showHide: !this.state.showHide });
        this.setState({ deleteirn: irn });
        this.setState({ activeflag: isactive });
    }

    // used to set a new state for the input
    // handleChangeselect = (event) => {
    //     this.setState({
    //         value: event.target.value
    //     });
    //     this.setState({
    //         customernamedata: event.target.value
    //     });

    // };

    handleChangeselect = (selectedcustomerOption) => {
        this.setState({ selectedcustomerOption });

        if (selectedcustomerOption !== null) {
            const key = 'irn';

            const arrayUniqueByKey = [...new Map(selectedcustomerOption.map(item =>
                [item[key], item])).values()];

            console.log(arrayUniqueByKey);
            this.state.customerirndata = arrayUniqueByKey.map(
                obj => {
                    return {
                        "irn": obj.irn
                        //"affiliatesirn" : obj.affiliatesirn

                    }
                }
            );
            console.log(this.state.customerirndata);
            this.state.affiliateirndata = arrayUniqueByKey.map(
                obj => {
                    return {
                        
                        "affiliatesirn" : obj.affiliatesirn

                    }
                }
            );
            console.log(this.state.affiliateirndata);
        }

       
      

    };

    // add or change the customer for the particular user 
    mapcustomers = () => {
        document
            .querySelectorAll(".error-border")
            .forEach((obj) => obj.classList.remove("error-border"));
        if (this.state.customerirndata.length === 0) {
            if (!this.validateSelect()) {
                toast.error("Please select the value.");
                return false;
            }
        }

        let value = document.getElementById('customerirn').value;
        let data = this.state.customerList.filter(x => x.irn === value)[0];
        const req = {};
        req.usersirn = this.state.usersirndata;
        req.affliateirn = this.state.affiliateirndata;
        req.password = '';
        req.customersirn = this.state.customerirndata;
        req.isactive = '1';
        req.userirn = '';
        req.username = this.state.firstname;
        req.activeflag = '0';
        req.emailid = this.state.emailiddata;
        req.adminflag = '1';
        req.creationuserid = this.state.userid;
        req.lastmoduserid = this.state.userid;
        req.userid = '';
        this.setState({ isLoading: true });
        console.log(req);
        // Add/Update Users information
        postingservice.saveUsersInformation(req)
            .then((response) => {

                if (req.usersirn === '') {
                    toast.error('Cannot be added');
                    return;
                }
                else {
                    this.setState({ showHide4: !this.state.showHide4 });
                    toast.success('User Added successfully.');
                    this.setState({ customernamedata: data.irn })
                    postingservice.getUsersInformation()
                        .then((response) => {
                            this.setState({ isLoading: false });
                            this.setState({ data: response.data });
                        })
                        .catch((error) => {
                            this.setState({ isLoading: false });
                        });
                }
            })

            .catch((error) => {
                this.setState({ isLoading: false });
            });
        this.setState({ email: '' })
        this.setState({ customernamedata: '' })
    }

    // email validation
    validateEmail(email) {
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    // validation for no email
    validate() {

        let isValid = true;
        document.querySelectorAll(".error-border").forEach(obj => obj.classList.remove("error-border"));
        if (this.state.email === undefined || this.state.email === "") {
            document.getElementById("email").classList.add("error-border");
            this.state.isHidden = true;
            isValid = false;
        }
        return isValid;

    }

    // validation when no customer is selected
    validateSelect() {
        let isValid = true;
        document.querySelectorAll(".error-border").forEach(obj => obj.classList.remove("error-border"));
        // if (document.getElementById('customerirn').value === "Select" || document.getElementById('customerirn').value === null) {
            document.getElementById("customerirn").classList.add("error-border");
            isValid = false;
        // }
        return isValid;
    }

    // search the user with email
    searchWithEmail() {
        var isValidEmail = this.validateEmail(this.state.email);

        document
            .querySelectorAll(".error-border")
            .forEach((obj) => obj.classList.remove("error-border"));
        if (this.state.email === undefined || this.state.email === "") {
            if (!this.validate()) {
                toast.error("Please enter the email address.");
                this.setState({ isHidden: true });
                return false;
            }
        }
        if (!isValidEmail || this.state.email.includes(".elanco") || this.state.email.includes(".elancoah") || this.state.email.includes("network")) {

            document.getElementById("email").classList.add("error-border");
            toast.error("Please enter valid  email address");
            this.setState({ isHidden: true });
            return;
        }

        this.setState({ isHidden: true });
        const req = {}
        req.emailid = document.getElementById('email').value;
        // get SAP CDC details based on email id
        postingservice.getsapCDCInfo(req)
            .then((response) => {
                // handle success
                if (response.data === '' || response.data === null || response.data === undefined) {
                    toast.error('User does not exists');
                    this.setState({ isHidden: true })
                    return;
                }
                else {
                  

                    if(response.data.length === undefined){
                    this.setState({ isHidden: false })
                    this.setState({ emaildata: response.data });
                    this.setState({ firstname: response.data.username });
                    this.setState({ emailiddata: response.data.emailid });
                    this.setState({ usersirndata: response.data.usersirn });

                     this.setState({ useriddata: response.data.userid });
                    const key = 'irn';
                    const arrayUniqueByKey = [...new Map(response.data.map(item =>
                        [item[key], item])).values()];
                    

                    this.state.selectedcustomerOption = arrayUniqueByKey.map(
                        obj => {
                            return {
                                "affiliatesirn": obj.affliateirn,
                                "customercode": obj.customercode,
                                "customername": obj.customername,
                                "irn": obj.irn,
                                "label": obj.customername,
                                "value": obj.customername
                            }
                        }
                    );
                    
                    // });
                    this.setState({ isHidden: false })
                   
                    }
                    else{
                        console.log(response.data)
                        this.setState({ isHidden: false })
                        this.setState({ emaildata: response.data });
                        this.setState({ firstname: response.data[0].username });
                        this.setState({ emailiddata: response.data[0].emailid });
                        this.setState({ usersirndata: response.data[0].usersirn });
    
                         this.setState({ useriddata: response.data[0].userid });
    
                       
                        const key = 'irn';
                        const arrayUniqueByKey = [...new Map(response.data.map(item =>
                            [item[key], item])).values()];
                        
    
                        this.state.selectedcustomerOption = arrayUniqueByKey.map(
                            obj => {
                                return {
                                    "affiliatesirn": obj.affliateirn,
                                    "customercode": obj.customercode,
                                    "customername": obj.customername,
                                    "irn": obj.irn,
                                    "label": obj.customername,
                                    "value": obj.customername
                                }
                            }
                        );
                        
                        this.setState({ isHidden: false })
                        
                    }
                }
            })
            .catch((error) => {
                // handle error
                this.setState({ isLoading: false });
            });
        this.setState({ selectedcustomerOption: '' })
    }

    // used to set a new state for the input
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    // Deactivate the user
    removeData() {
        let req = {};
        req.usersirn = this.state.deleteirn
        req.isactive = '0'
        postingservice.deleteCustomer(req).then((response) => {
            // handle success    
            this.setState({ showHide2: !this.state.showHide2 })
            this.setState({ isLoading: false });
            this.setState({ deletedata: response.data })
            toast.success("Deactivated Successfully");
            this.setState({ isHidden: true })
            // table data 
            postingservice.getUsersInformation()
                .then((response) => {
                    this.setState({ data: response.data });
                })
                .catch((error) => {
                    this.setState({ isLoading: false });
                });
        }).catch((error) => {
            // handle error
            this.setState({ isLoading: false });
            toast.error("Not Deactivated Successfully");
        });
        this.setState({ deletedata: this.state.deletedata })
        this.setState({ email: '' })
    }

    // activate the user
    removeData1() {
        let req = {};
        req.usersirn = this.state.deleteirn
        req.isactive = '1'
        postingservice.deleteCustomer(req).then((response) => {
            // handle success    
            this.setState({ showHide: !this.state.showHide })
            this.setState({ isLoading: false });
            this.setState({ deletedata: response.data })
            toast.success("Activated Successfully");
            this.setState({ isHidden: true })
            // table data 
            postingservice.getUsersInformation()
                .then((response) => {
                    // handle success
                    this.setState({ data: response.data });
                })
                .catch((error) => {
                    // handle error
                    this.setState({ isLoading: false });
                });
        }).catch((error) => {
            // handle error
            this.setState({ isLoading: false });
            toast.error("Not Deactivated Successfully");
        });
        this.setState({ deletedata: this.state.deletedata })
        this.setState({ email: '' })
    }

    render() {
        return (

            <LoadingOverlay active={this.state.isLoading} spinner >
                <div style={{ marginTop: "20px" }} ></div>
                <div className="mt-4">
                    {this.state.activeflag === '1' ?
                        <Modal
                            show={this.state.showHide2}
                            centered
                            onHide={() => this.handleModal1ShowHide()}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton centered="true">
                                <Modal.Title>Confirm</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label htmlFor="" className="col-form-label mr-2 pt-1">
                                    Are you sure you want to Deactivate?
                                </label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.removeData()}
                                >
                                    &nbsp; Yes &nbsp;
                                </button>
                                <button
                                    className="btn btn-link"
                                    onClick={() => this.handleModal1ShowHide()}
                                >
                                    No
                                </button>
                            </Modal.Footer>
                        </Modal>
                        :
                        <Modal
                            show={this.state.showHide}
                            centered
                            onHide={() => this.handleModalShowHide()}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton centered="true">
                                <Modal.Title>Confirm</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label htmlFor="" className="col-form-label mr-2 pt-1">
                                    Are you sure you want to  Activate ?
                                </label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    id='activateDeactivateYesButton2'
                                    onClick={() => this.removeData1()}
                                >
                                    &nbsp; Yes &nbsp;
                                </button>
                                <button
                                    className="btn btn-link"
                                    id='activateDeactivateNoButton2'
                                    onClick={() => this.handleModalShowHide()}
                                >
                                    No
                                </button>
                            </Modal.Footer>
                        </Modal>
                    }
                    <div className="col-md-12">
                        <div className="panel-bg  mt-2">
                            {" "}

                            <div className="panelTitle p-0 d-flex">
                                <div className="col-6 my-auto">
                                    <strong> Customer Users </strong>
                                </div>
                                <div className="col-6 pl-2 pr-1 pt-1 pb-2 my-auto text-right">
                                    <button className="btn btn-primary" onClick={() => this.handleModal4ShowHide()}>&nbsp;Map User&nbsp;</button>
                                    <Modal
                                        dialogClassName="modal-60w"
                                        show={this.state.showHide4}
                                        onHide={() => this.handleModal4ShowHide()}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton centered="true">
                                            <Modal.Title>Map User</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row mb-4">
                                                <div className="col-sm-12 ml-2">
                                                    <form className="form-inline">
                                                        <div className="form-group mt-2 col-sm-12">
                                                            <label for="email">User Email&nbsp;:&nbsp;<span className="req">*</span>{" "}</label>
                                                            <input type="text" placeholder="Email" className="form-control ml-2 col-sm-3" id="email" name="email" onChange={this.handleChange} value={this.state.email} />
                                                            <button type="button" className="btn btn-primary  mb-2 mr-sm-4 ml-4 mt-2 mb-0" onClick={() => this.searchWithEmail()}>Search</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            {!this.state.isHidden &&
                                                <div className="dashboard mb-4" >
                                                    <div className="row pr-4 background-gray mt-1">
                                                        <div className="col-sm-3 pt-3 border-right">
                                                            <p className="dashboardlabels">Name</p>
                                                            <p className="dashboardlabeldynamicdata">{this.state.firstname}</p>
                                                        </div>
                                                        <div className="col-sm-4 pt-3 border-right">
                                                            <p className="dashboardlabels">Email Address</p>
                                                            <p className="dashboardlabeldynamicdata">
                                                                {this.state.emailiddata}
                                                            </p>
                                                        </div>
                                                        <div className="col-sm-5 pt-3">
                                                            <p className="dashboardlabels">Select Customer&nbsp;<span className="req">*</span>{" "}</p>
                                                            <form className="form-inline" action="">

                                                                <Select
                                                                    data-test="pocketpet"
                                                                    id="customerirn"
                                                                    name='customernamedata'
                                                                    value={this.state.selectedcustomerOption}
                                                                    onChange={this.handleChangeselect}
                                                                    options={this.state.customerList}
                                                                    isMulti={true}
                                                                  //  className="form-control custom-form-control mb-2 mr-sm-4 col-sm-8 "

                                                                />
                                                                &nbsp;&nbsp;
                                                                <button className="btn btn-primary  mb-2 " id='mapcustomerbutton' 
                                                                 style={{ display: this.state.usersirndata !== null ? 'none' : '' }}
                                                                    type="button" onClick={() => this.mapcustomers()} > &nbsp;&nbsp;Map &nbsp;&nbsp;</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                            <div className="p-3 mb70" style={{ marginBottom: "180px" }}>
                                {/* <!--  Table Panel Start --> */}
                                <ToolkitProvider
                                    keyField="ReportId"
                                    data={this.state.data.sort(this.sortIt('isactive'))}
                                    columns={this.columns}
                                >
                                    {(props) => (
                                        <div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                pagination={paginationFactory({ ...options })}
                                                noDataIndication={() => <NoDataIndication />}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                                {/* table panel ends */}
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }
}


